"use client";
import { Fragment } from "react";
import { Box, Collapse, Stack, Divider } from "@mui/material";
import { Masonry } from "@mui/lab";
import useSWR from "swr";

import { useStore, observer } from "../../../../service/mobx";
import { fetcher } from "../../../../service/graph";
import { CardProductSize2 } from "../../../../component/Card/Product";
import MoreButton from "../../../../component/Button/More";
import LabelWithIcon from "../../../../component/LabelWithIcon";
import { entityMap } from "../../../../component/Search/Hooks";
import Distill from "./Distill";
import ChipFilter from "../../../../component/Chip/Filter";
import { useStarCallback } from "../../../../component/Star";

function Following() {
  const { user, stars } = useStore();

  return user.loaded
    ? stars.tasks.map(tag => (
        <Fragment key={tag.id}>
          <Divider />
          <Row task={tag.name} />
        </Fragment>
      ))
    : null;
}

export default observer(Following);

const Row = observer(function Row({ task }) {
  const { device } = useStore();
  const { data = {} } = useSWR(`api/following/${task}`, fetcher);
  const { papers, count } = data;
  const cards =
    papers?.map(product => (
      <div key={product.id}>
        <CardProductSize2 prefetch tags paper={product} />
      </div>
    )) ?? [];
  const columns = {
    compact: 1,
    medium: 2,
    expanded: 1,
    large: 2,
    extraLarge: 3
  };

  cards.splice(
    columns[device.size] - 1,
    0,
    <div key="distill">
      <Distill papers={papers} />
    </div>
  );

  return (
    <Collapse mountOnEnter in={count !== undefined}>
      <div>
        <Stack
          useFlexGap
          pb={1}
          flexWrap="wrap"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <LabelWithIcon label={task} Icon={entityMap.task.Icon} />
          <FollowButton task={task} />
        </Stack>
        <Masonry spacing={2} columns={columns}>
          {cards}
          <Box py={2}>
            <MoreButton
              count={count}
              entity="task"
              url={`/search?type=papers&task=${encodeURIComponent(task)}`}
            />
          </Box>
        </Masonry>
      </div>
    </Collapse>
  );
});
const FollowButton = observer(function FollowButton({ task }) {
  const { stars } = useStore();
  const tag = stars.tasks.find(tag => tag.name === task);
  const starEvents = useStarCallback(tag);

  return (
    <ChipFilter
      small
      selected
      label="Following"
      sx={{
        bgcolor: "transparent",
        "& > *": {
          color: "secondary.color"
        }
      }}
      {...starEvents}
    />
  );
});
