"use client";

import { observer, useStore } from "../../../../../service/mobx";
import TaskLayout from "./Layout";
import MoreButton from "../../../../../component/Button/More";

function FollowingTasks() {
  const { stars } = useStore();

  return (
    <TaskLayout
      label="Following"
      list={stars.tasks.slice(0, 5)}
      button={
        5 < stars.tasks.length ? (
          <MoreButton
            m="unset"
            entity="stars"
            justifyContent="flex-end"
            text="See everything you're following"
            url="/search?type=tasks&stars=all"
          />
        ) : undefined
      }
    />
  );
}

export default observer(FollowingTasks);
