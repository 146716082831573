"use client";
import { Box } from "@mui/material";
import Masonry from "react-responsive-masonry";

import MoreButton from "../../../../component/Button/More";
import LabelWithIcon from "../../../../component/LabelWithIcon";
import { useStore, observer } from "../../../../service/mobx";

function TrendingMasonry({
  spacing = 1,
  trending,
  Card,
  label,
  Icon,
  cta,
  url,
  count,
  disabled,
  prefetch,
  entityName = "paper",
  columns = { compact: 1, medium: 2, expanded: 1, large: 2, extraLarge: 3 }
}) {
  const { device } = useStore();
  const columnsCount = columns[device.size] || 2;

  return (
    <Box pb={2}>
      <LabelWithIcon Icon={Icon} label={label} />
      {/* <ResponsiveMasonry
        columnsCountBreakPoints={{
          0: 1,
          600: 2,
          840: 1,
          1200: 2,
          1600: 3
        }}
      > */}
      <Masonry gutter={`${8 * spacing}px`} columnsCount={columnsCount}>
        {trending.map(entity => (
          <Card
            key={entity.id}
            prefetch={prefetch}
            disabled={disabled}
            {...{ [entityName]: entity }}
          />
        ))}
        {disabled ? null : (
          <MoreButton count={count} url={url} text={cta} entity={entityName} />
        )}
      </Masonry>
      {/* </ResponsiveMasonry> */}
    </Box>
  );
}

export default observer(TrendingMasonry);
