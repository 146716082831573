"use client";
import { Fragment, useState } from "react";
import Image from "next/image";
import { Stack, CardHeader, Typography, Box, Grid } from "@mui/material";

import { useStore } from "../../service/mobx";
import Star from "../Star";
import FilledCard from "../Card/Filled";
import { textRank } from "../../service/mobx/utilities";
import { ModelCardSize1 } from "./Model";
import GameBadge from "../GameBadge";
import { menuOptionsPublisher } from "../Menu/menus/Search/Publisher";
import { Tasks } from "./Paper";
import RenderPaperTitle from "../Markdown/PaperTitle";

function CardProductSize2({ paper, tags = false, prefetch }) {
  const [imgTaskFailed, setTaskFailed] = useState(paper.imgTask === undefined);
  const [imgPaperFailed, setPaperFailed] = useState(
    paper.imgPaper === undefined
  );

  return (
    <Card paper={paper} prefetch={prefetch}>
      <Grid
        container
        columns={10}
        sx={{ "&:hover .overlay": { bgcolor: "unset" } }}
        flexDirection={{ compact: "column", expanded: "row" }}
      >
        {imgPaperFailed ? null : (
          <Grid item compact={12}>
            <Box
              m={-2}
              mb={2}
              overflow="hidden"
              position="relative"
              height={160}
              borderRadius={theme => theme.shape.md.top}
              sx={{
                "& img": {
                  width: "100%",
                  display: "block",
                  objectFit: "cover",
                  objectPosition: "top center"
                }
              }}
            >
              <Image
                fill
                src={imgTaskFailed ? paper.imgPaper : paper.imgTask}
                alt={paper.title}
                // blurDataURL={paper.imageB64}
                // placeholder={paper.imageB64 ? "blur" : undefined}
                sizes="(max-width: 600px) 320px, 480px"
                onError={() => {
                  if (!imgTaskFailed) {
                    setTaskFailed(true);
                  } else {
                    setPaperFailed(true);
                  }
                }}
              />
              <Box
                className="overlay"
                sx={theme => ({
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  mixBlendMode: "multiply",
                  bgcolor: theme.palette.alpha(
                    theme.palette.surface.container.color,
                    0.3
                  ),
                  transition: theme.transitions.create("all")
                })}
              />
            </Box>
          </Grid>
        )}
        <Grid item compact={12} medium>
          {/* <span> */}
          <Header org publisher product={paper} />
          {/* </span> */}
          <Models paper={paper} />
          {tags ? (
            <Tasks
              small
              paddingLeft={!!paper.scoreTrending || !!paper.scoreSota}
              tags={paper.tags}
            />
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
}
function CardProductSize3({ paper }) {
  // const Status = null;

  return (
    <Card paper={paper}>
      <Header org authors publisher product={paper} />
      <Models paper={paper} />
    </Card>
  );
}

function CardProductSize4({ paper }) {
  // const Status = null;

  return (
    <Card paper={paper}>
      <Header org authors publisher product={paper} />
      <Summary paper={paper} />
      <Models paper={paper} />
    </Card>
  );
}

export { CardProductSize2, CardProductSize3, CardProductSize4 };

function Card({ paper, children, ...props }) {
  const { analytics } = useStore();
  const { publisher, paperID } = paper;

  return (
    <FilledCard
      tone="surface.container.color"
      href={`docs/${publisher}/${paperID}/paper`}
      onClick={() => {
        analytics.track.event("Paper Click", { publisher, paperID });
      }}
      {...props}
    >
      {children}
    </FilledCard>
  );
}
const Models =
  (() => null) ||
  (({ paper }) =>
    paper.models?.length ? (
      <Stack
        spacing={1}
        direction="row"
        flexWrap="wrap"
        pl={paper.status ? 7 : undefined}
        sx={{
          "& > *": {
            mt: 1
          }
        }}
      >
        {paper.models.map(model => (
          <ModelCardSize1 key={model.id} model={model} />
        ))}
      </Stack>
    ) : undefined);

function SubHeader({
  product,
  task = false,
  org = false,
  params = false,
  authors = false,
  publisher = false
  // downloads = false
}) {
  const { utilities } = useStore();

  try {
    const authorArray =
      product.authorArray ?? JSON.parse(product?.authors ?? "[]");
    const remainingAuthorsLength = authorArray.slice(1).length;
    var authorString = `${authorArray.slice(0, 1).join(", ")}${
      remainingAuthorsLength ? " + " + remainingAuthorsLength : ""
    }`;
  } catch {
    // console.error(error);
    // console.warn(paper?.authors ?? paper);
  }
  const items = [
    utilities.paperTime(product.published || product.repo?.created),
    org ? product.org : undefined,
    authors ? authorString : undefined,
    publisher ? menuOptionsPublisher[product.publisher] : undefined,
    // downloads && product.hf?.downloads
    //   ? `${utilities.formatNumber(product.hf.downloads)} downloads`
    //   : undefined,
    task && product.task ? `${product.task}` : undefined,
    params && product.params
      ? `${utilities.formatNumber(product.params)} params`
      : undefined
    // likes && product.hf?.likes
    //   ? `${utilities.formatNumber(product.hf.likes)} likes`
    //   : undefined
  ].filter(defined => defined);

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" width="100%">
      {items.map((content, index, array) => (
        <Fragment key={index}>
          {content}
          {index + 1 === array.length ? undefined : (
            <Box component="span" mx={0.5}>
              ·
            </Box>
          )}
        </Fragment>
      ))}
    </Stack>
  );
}
export const Header = ({
  product,
  size = "medium",
  noStar,
  ...subheaderProps
}) => (
  <CardHeader
    avatar={
      Number.isFinite(product?.scoreTrending) ||
      Number.isFinite(product?.scoreSimilarity) ? (
        <GameBadge
          scoreTrending={product.scoreTrending}
          scoreSimilarity={product.scoreSimilarity}
          models={product.models}
        />
      ) : undefined
    }
    title={
      product?.displayName ? (
        <>
          <Box component="span" color="secondary.color" fontWeight={300}>
            {`${product.org} `}
          </Box>
          {product.displayName}
        </>
      ) : (
        <RenderPaperTitle paperTitle={product.title} />
      )
    }
    titleTypographyProps={{
      variant: "headlineMd",
      color: "surface.on.color",
      sx: theme => theme.typography.lineClamp(3),
      typography: {
        compact: size === "small" ? "labelMd" : "titleMd",
        expanded:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : size === "xl"
            ? "titleLg"
            : "titleSm",
        large:
          size === "small"
            ? "labelLg"
            : size === "large"
            ? "titleLg"
            : size === "xl"
            ? "headlineMd"
            : "titleMd"
      }
    }}
    subheader={<SubHeader product={product} size={size} {...subheaderProps} />}
    subheaderTypographyProps={{
      color: "surface.on.color",
      typography: {
        compact: "bodySm",
        expanded:
          size === "xl" ? "bodyLg" : size === "small" ? "bodySm" : "bodyMd"
      }
    }}
    action={
      noStar ? undefined : (
        <Box px={1}>
          <Star size={size} entity={product} />
        </Box>
      )
    }
    sx={{ p: 0, alignItems: "flex-start" }}
  />
);

function Summary({ paper, size, sentences = 2 }) {
  const mlSummary = paper?.summaries?.[0]?.summary;
  const summary = textRank(mlSummary ?? paper.summary, sentences);

  return summary ? (
    <Typography
      paragraph
      color="surface.on.color"
      variant="paperBody2"
      sx={
        size === 3
          ? theme => theme.typography.lineClamp(2)
          : theme => theme.typography.lineClamp(7)
      }
    >
      {summary}
    </Typography>
  ) : null;
}
