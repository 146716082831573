"use client";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { observer, useStore } from "../../../../../../service/mobx";
import TaskLayout from "../Layout";
import MoreButton from "../../../../../../component/Button/More";
import ButtonFilled from "../../../../../../component/Button/Filled";
import useSignInDialog from "../../../../../../component/Dialog/dialogs/appWide/Login";

function TrendingTasks({ trending }) {
  const [top5, setTop5] = useState(trending.slice(0, 5));
  const { stars, user } = useStore();
  const onClick = useSignInDialog("Sign in to create your feed");

  useEffect(() => {
    if (user.isAnonymous === false) {
      const top5 = [];

      for (const task of trending) {
        if (stars.map.has(task.id) === false) {
          top5.push(task);

          if (top5.length === 5) {
            break;
          }
        }
      }

      setTop5(top5);
    }
  }, [trending, user.isAnonymous, stars.map]);

  return (
    <>
      {user.isAnonymous ? (
        <Box pb={2}>
          <ButtonFilled onClick={onClick} label="Sign in to tune your feed" />
        </Box>
      ) : null}
      <TaskLayout
        full
        label="trending Today"
        list={top5}
        button={
          user.isAnonymous ? undefined : (
            <MoreButton
              text="View all topics"
              entity="task"
              url="/search?type=tasks"
              m="unset"
              justifyContent="flex-end"
            />
          )
        }
      />
    </>
  );
}

export default observer(TrendingTasks);
